<template>
  <div>
    <vue-element-loading
      :active="appLoading"
      :is-full-screen="true"
      background-color="#FFFFFF"
      color="#68D389  "
      spinner="bar-fade-scale"
    />
    <ServerError v-if="ServerError" />
    <v-snackbar v-model="showSnackBar" color="#68D389" right :timeout="timeout">
      <v-layout wrap justify-center>
        <v-flex text-left class="align-self-center">
          <span style="color: #ffffff">
            {{ msg }}
          </span>
        </v-flex>
        <v-flex text-right>
          <v-btn small :ripple="false" text @click="showSnackBar = false">
            <v-icon style="color: #ffffff">mdi-close</v-icon>
          </v-btn>
        </v-flex>
      </v-layout>
    </v-snackbar>
    <v-layout wrap justify-center>
      <v-flex xs12 text-uppercase text-center py-10>
        <span class="itemHeading"
          >Eco-Development Committee/Tribal Welfare
        </span>
      </v-flex>
      <v-flex xs12 md12 px-md-3 px-lg-3 px-xl-3>
        <v-layout wrap>
          <v-flex xs12 md12>
            <v-layout wrap>
              <v-flex xs12>
                <v-text-field
                  class="textField2"
                  dense
                  v-model="about.pageName"
                  outlined
                  label="Name of the Page"
                  required
                ></v-text-field>
              </v-flex>
              <v-flex xs12>
                <v-text-field
                  class="textField2"
                  dense
                  v-model="about.title"
                  outlined
                  label="Title of the  Eco-Development Committee/Tribal Welfare"
                  required
                ></v-text-field>
              </v-flex>
              <v-flex xs12 md12 pt-4 text-left>
  <span class="textField1"
    >Please add the Protection left text
  </span>
  <vue-editor
    class="textField2 pt-1"
    v-model="about.text"
  ></vue-editor>
</v-flex>


            </v-layout>
          </v-flex>
          <v-flex md12 xs12>
            <ImageComp
              @stepper="winStepper"
              :singleImage="about.topimage"
              :pageId="about._id"
              :height="'2118'"
              :width="'8001'"
              :heading="'Upload Top Image'"
              :componentType="'topImage'"
            />
          </v-flex>
          <v-flex pt-5>
            <ImageComp
              @stepper="winStepper"
              :singleImage="about.image"
              :pageId="about._id"
              :height="'2317'"
              :width="'1277'"
              :heading="'Upload Image'"
              :componentType="'image'"
            />
          </v-flex>
          <v-flex md12 pt-4>
            <v-layout justify-end>
              <v-flex md3>
                <v-btn
                  block
                  tile
                  color="#68D389"
                  light
                  :ripple="false"
                  depressed
                  @click="validateInput"
                  class="itemValue"
                >
                  <span style="color: #ffffff">Save Changes </span>
                </v-btn>
              </v-flex>
            </v-layout>
          </v-flex>
        </v-layout>
      </v-flex>
    </v-layout>
  </div>
</template>
<script>
import axios from "axios";
import ImageComp from "@/components/CommonComponents/singleImage";
export default {
  components: {
    ImageComp,
  },
  data() {
    return {
      about: {
        title: null,
        pageName: null,
      },
      image: null,
      formData: new FormData(),
      id: null,
      valid: false,
      imageArray: [],
      formDataTop: new FormData(),
      topImage: null,
      appLoading: false,
      ServerError: false,
      showSnackBar: false,
      timeout: 5000,
      msg: null,
    };
  },
  mounted() {
    this.getData();
  },
  methods: {
    winStepper(window_data) {
      if (window_data.type == "topImage") {
        this.topImage = window_data.selectedFiles;
      }
      if (window_data.type == "image") {
        this.image = window_data.selectedFiles;
      }
    },
    validateInput() {
      if (!this.about.title) {
        this.msg = "Please Provide Title";
        this.showSnackBar = true;
        return;
      }
      
      else if (!this.about.text) {
        this.msg = "Please Provide Text";
        this.showSnackBar = true;
        return;
      } 
      
      
      
      else if (!this.about.pageName) {
        this.msg = "Please Provide Page name";
        this.showSnackBar = true;
        return;
      } 
      
      
      else if (!this.topImage && !this.about.topimage) {
        this.msg = "Please Upload Top Image";
        this.showSnackBar = true;
        return;
      } 
      
      else if (!this.image && !this.about.image) {
        this.msg = "Please Upload Image";
        this.showSnackBar = true;
        return;
      } else {
        this.addAbout();
      }
    },
    getData() {
      this.appLoading = true;
      axios({
        method: "GET",
        url: "/static/organisationalchart/get",
        headers: {
          token: localStorage.getItem("token"),
        },
      })
        .then((response) => {
          this.appLoading = false;
          this.about = response.data.data;
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
    addAbout() {
      this.formData.append("image", this.image);
      this.formData.append("title", this.about.title);
      this.formData.append("pageName", this.about.pageName);
      this.formData.append("text", this.about.text);   
      axios({
        method: "post",
        url: "/static/organisationalchart/set",
        data: this.formData,
        headers: {
          token: localStorage.getItem("token"),
        },
      })
        .then((response) => {
          this.appLoading = false;
          if (response.data.status) {
            // this.msg = "Added Successully";
            Object.keys(this.about).forEach((key) => (this.about[key] = null));
            if (this.topImage) {
              this.uploadTopImages();
            }
            this.msg = response.data.msg;
            this.showSnackBar = true;
            this.getData();
          } else {
            this.msg = response.data.msg;
            this.showSnackBar = true;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          console.log(err);
        });
    },

    uploadTopImages() {
      this.appLoading = true;
      this.formDataTop.append("image", this.topImage);
      axios({
        method: "POST",
        url: "/static/organisationalchart/topimage",
        data: this.formDataTop,
        headers: {
          "Content-Type": "multipart/form-data",
          token: localStorage.getItem("token"),
        },
      })
        .then((response) => {
          this.appLoading = false;
          if (response.data.status == true) {
            this.msg = "Added Successfully";
          } else {
            this.msg = "Can't Upload Top Image.. Please Try Again Later";
            this.showsnackbar = true;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.msg = "Can't Upload Top Image.. Please Try Again Later";
          this.showsnackbar = true;
          console.log(err);
        });
    },
  },
};
</script>