<template>
  <div>
    <!-- <v-layout wrap justify-start>
      <v-flex xs12 text-left >
        <span style="font-size:22px;font-family: poppinssemibold"
          >Learn about the Reserve</span
        >
      </v-flex>
    </v-layout> -->
    <v-layout wrap justify-center>
      <v-flex xs12 lg11>
        <v-layout wrap justify-space-between py-2>
          <v-flex v-for="(item, i) in navItems" :key="i" px-2>
            <router-link :to="'/learnAboutTheReserve?content=' + item.name">
              <v-btn
                block
                text
                :ripple="false"
                :style="{
                  border:
                    $route.query.content == item.name
                      ? '1px solid #ffe200'
                      : !$route.query.content && item.name == 'Protection'
                      ? '1px solid #ffe200'
                      : 'none',
                }"
              >
                <span
                  style="
                    font-family: poppinssemibold;
                    font-size: 14px;
                    color: #343434;
                    text-transform: none;
                  "
                >
                  {{ item.name }}
                </span>
              </v-btn>
            </router-link>
          </v-flex>
        </v-layout>
      </v-flex>
    </v-layout>
    <v-layout wrap justify-center>
      <v-flex xs12>
        <div
          v-if="$route.query.content == 'PROTECTION' || !$route.query.content"
        >
          <ParambikulamTigerReserve />
        </div>
        <div v-if="$route.query.content == 'HABITAT MANAGEMENT'">
          <FDA />
        </div>
        <StoryOfTheReserve
          v-if="$route.query.content == 'CAPTIVE ELEPHANT MANAGEMENT'"
        />
        <div v-if="$route.query.content == 'ECO TOURISM ACTIVITIES'">
          <KeralaForestDepartment />
        </div>
        <div
          v-if="
            $route.query.content == 'ECO-DEVELOPMENT COMMITTEE/ TRIBAL WELFARE'
          "
        >
          <OrganisationalChart />
        </div>
        <div v-if="$route.query.content == 'Reports'">
          <Reports />
        </div>
      </v-flex>
    </v-layout>
  </div>
</template>
<script>
import ParambikulamTigerReserve from "./parambikulamTigerReserve";
import FDA from "./fda";
import StoryOfTheReserve from "./storyoftheReserve";
import KeralaForestDepartment from "./keralaForestDepartment";
import OrganisationalChart from "./organisationalChart";
// import Reports from "./reports";

export default {
  components: {
    ParambikulamTigerReserve,
    FDA,
    StoryOfTheReserve,
    KeralaForestDepartment,
    OrganisationalChart,
    // Reports,
  },
  data() {
    return {
      navItems: [
        { name: "PROTECTION" },
        { name: "HABITAT MANAGEMENT" },
        { name: "CAPTIVE ELEPHANT MANAGEMENT" },
        { name: "ECO TOURISM ACTIVITIES" },
        { name: "ECO-DEVELOPMENT COMMITTEE/ TRIBAL WELFARE" },
        // { name: "Reports" },
      ],
    };
  },
};
</script>
